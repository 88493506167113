var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.$t("Create new Atlasposting staff")) + ". ")]), !_vm.loading ? _c('wizard-left', {
    ref: "userCreateWizardForm",
    attrs: {
      "clickable-steps": false
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('User setup'),
            "desc": _vm.$t('Add information'),
            "icon": "General/User.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var currentStep = _ref.currentStep,
          goPrev = _ref.goPrev;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var handleSubmit = _ref2.handleSubmit;
              return [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 1 ? _c('validation-observer', {
                ref: "validator-1"
              }, [_c('div', {
                staticClass: "wizard-step-container"
              }, [_c('h4', {
                staticClass: "mb-3 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Physical Person Setup")))]), _c('p', {
                staticClass: "mb-3 mt-8 font-weight-bold"
              }, [_vm._v(_vm._s(_vm.$t("Add login email")) + ":")]), _c('validation-provider', {
                ref: "userValidator",
                attrs: {
                  "name": _vm.$t('Email'),
                  "vid": "mail",
                  "rules": {
                    required: true,
                    email_unique: _vm.physicalPersonInfo.login_email
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "error-messages": errors,
                        "label": "".concat(_vm.$t('Email (this email will be used for login)'), "*"),
                        "placeholder": _vm.$t('Please enter your email')
                      },
                      model: {
                        value: _vm.physicalPersonInfo.login_email,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "login_email", $$v);
                        },
                        expression: "physicalPersonInfo.login_email"
                      }
                    })];
                  }
                }], null, true)
              }), _c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Groups'),
                  "vid": "groups"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('p', {
                      staticClass: "mb-6 mt-5 font-weight-bold"
                    }, [_vm._v(_vm._s(_vm.$t("Add physical person groups")) + ":")]), _c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Groups'), "*"),
                        "placeholder": _vm.$t('Select groups'),
                        "options": _vm.GROUPS,
                        "searchable": "",
                        "multiple": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.physicalPersonInfo.groups,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "groups", $$v);
                        },
                        expression: "physicalPersonInfo.groups"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('p', {
                staticClass: "mb-6 mt-8 font-weight-bold"
              }, [_vm._v(_vm._s(_vm.$t("Physical person details")) + ":")])]), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Name'),
                  "rules": "required|max:30"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('First name'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.physicalPersonInfo.name,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "name", $$v);
                        },
                        expression: "physicalPersonInfo.name"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Last name'),
                  "vid": "surname",
                  "rules": "required|max:30"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref6) {
                    var errors = _ref6.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Last name'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.physicalPersonInfo.surname,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "surname", $$v);
                        },
                        expression: "physicalPersonInfo.surname"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "gender": _vm.$t('Gender'),
                  "vid": "gender",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "options": _vm.GENDERS,
                        "label": "".concat(_vm.$t('Gender'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.physicalPersonInfo.gender,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "gender", $$v);
                        },
                        expression: "physicalPersonInfo.gender"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Date of birth'),
                  "vid": "date_birth"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref8) {
                    var errors = _ref8.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "overrides": _vm.datePickerOverrides,
                        "error-messages": errors,
                        "label": "".concat(_vm.$t('Date of birth'))
                      },
                      model: {
                        value: _vm.physicalPersonInfo.date_birth,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "date_birth", $$v);
                        },
                        expression: "physicalPersonInfo.date_birth"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Place of birth'),
                  "vid": "place_birth"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref9) {
                    var errors = _ref9.errors;
                    return [_c('euro-place-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Place of birth')),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.physicalPersonInfo.place_birth,
                        callback: function callback($$v) {
                          _vm.$set(_vm.physicalPersonInfo, "place_birth", $$v);
                        },
                        expression: "physicalPersonInfo.place_birth"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Citizenships'),
                  "vid": "citizenships",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref10) {
                    var errors = _ref10.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Citizenships'), "*"),
                        "placeholder": _vm.$t('Select citizenships'),
                        "options": _vm.nationsSelectable,
                        "multiple": "",
                        "searchable": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.citizenshipsComp,
                        callback: function callback($$v) {
                          _vm.citizenshipsComp = $$v;
                        },
                        expression: "citizenshipsComp"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])], 1)]) : _vm._e()], 1), currentStep === 2 ? _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Confirm information to send invite")) + ":")]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("General Information")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.name))])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Last name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.surname))])])]), _vm.physicalPersonInfo.telcontact_set.length > 0 ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Telephone Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.physicalPersonInfo.telcontact_set[0].number) + " ")])]) : _vm._e()]) : _vm._e(), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn-left', {
                on: {
                  "click": goPrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn-left', {
                attrs: {
                  "label": _vm.$t('Save as Confirmed')
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }), _c('wizard-next-btn-left', {
                on: {
                  "click": _vm.handleNext
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }], null, false, 1828252764)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }