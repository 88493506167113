import api from "@/core/services/api.service";
export default {
  get PATH() {
    return "group";
  },

  async getAllNotPaginated() {
    const r = await api.get(`${this.PATH}/`, {
      params: {
        limit: 999,
        offset: 0,
      },
    });

    return r.data;
  },
};
