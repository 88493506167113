<template>
  <b-card>
    <b-card-title> {{ $t("Create new Atlasposting staff") }}. </b-card-title>

    <wizard-left v-if="!loading" ref="userCreateWizardForm" :clickable-steps="false">
      <template #wizard-nav-left>
        <wizard-nav-step-left :title="$t('User setup')" :desc="$t('Add information')"
          icon="General/User.svg"></wizard-nav-step-left>
        <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
      </template>

      <template #default="{ currentStep, goPrev }">
        <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
          <wizard-step-left class="custom-wizard-step">
            <validation-observer v-if="currentStep === 1" ref="validator-1">

              <div class="wizard-step-container">
                <h4 class="mb-3 font-weight-bold text-dark">{{ $t("Physical Person Setup") }}</h4>
                <p class="mb-3 mt-8 font-weight-bold">{{ $t("Add login email") }}:</p>
                <validation-provider ref="userValidator" v-slot="{ errors }" :name="$t('Email')" vid="mail" :rules="{
                  required: true,
                  email_unique: physicalPersonInfo.login_email,
                }">
                  <euro-input v-model="physicalPersonInfo.login_email" :error-messages="errors"
                    :label="`${$t('Email (this email will be used for login)')}*`"
                    :placeholder="$t('Please enter your email')"></euro-input>
                </validation-provider>

                <div class="form row">
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Groups')" vid="groups">
                      <p class="mb-6 mt-5 font-weight-bold">{{ $t("Add physical person groups") }}:</p>
                      <euro-select v-model="physicalPersonInfo.groups" :label="`${$t('Groups')}*`"
                        :placeholder="$t('Select groups')" :options="GROUPS" searchable multiple
                        :error-messages="errors"></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <p class="mb-6 mt-8 font-weight-bold">{{ $t("Physical person details") }}:</p>
                  </div>
                  <!-- <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Avatar')" vid="avatar_tus">
                      <avatar-input :error-messages="errors"
                        @change="tusId => { physicalPersonInfo.avatar_tus = tusId; }"></avatar-input>
                    </validation-provider>
                  </div> -->
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required|max:30">
                      <euro-input v-model="physicalPersonInfo.name" :label="`${$t('First name')}*`"
                        :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname"
                      rules="required|max:30">
                      <euro-input v-model="physicalPersonInfo.surname" :label="`${$t('Last name')}*`"
                        :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :gender="$t('Gender')" vid="gender" rules="required">
                      <euro-radio v-model="physicalPersonInfo.gender" :options="GENDERS" :label="`${$t('Gender')}*`"
                        :error-messages="errors"></euro-radio>
                    </validation-provider>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Date of birth')" vid="date_birth">
                      <date-picker v-model="physicalPersonInfo.date_birth" :overrides="datePickerOverrides"
                        :error-messages="errors" :label="`${$t('Date of birth')}`">
                      </date-picker>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Place of birth')" vid="place_birth"
                      >
                      <euro-place-input v-model="physicalPersonInfo.place_birth" :label="`${$t('Place of birth')}`"
                        :error-messages="errors"></euro-place-input>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Citizenships')" vid="citizenships"
                      rules="required">
                      <euro-select v-model="citizenshipsComp" :label="`${$t('Citizenships')}*`"
                        :placeholder="$t('Select citizenships')" :options="nationsSelectable" multiple searchable
                        :error-messages="errors"></euro-select>
                    </validation-provider>
                  </div>
                </div>

              </div>
            </validation-observer>
          </wizard-step-left>
          <wizard-step-left v-if="currentStep === 2" class="custom-wizard-step">
            <h6 class="font-weight-bolder mb-3">{{ $t("Confirm information to send invite") }}:</h6>
            <div class="separator separator-dashed my-5"></div>
            <h6 class="font-weight-bolder mb-3">{{ $t("General Information") }}</h6>
            <div class="text-dark-50 line-height-lg">
              <div>
                {{ $t("Name") }}: <span>{{ physicalPersonInfo.name }}</span>
              </div>
              <div>
                {{ $t("Last name") }}:
                <span>{{ physicalPersonInfo.surname }}</span>
              </div>
            </div>
            <div v-if="physicalPersonInfo.telcontact_set.length > 0">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Telephone Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                {{ physicalPersonInfo.telcontact_set[0].number }}
              </div>
            </div>
          </wizard-step-left>

          <wizard-actions-left>
            <div class="mr-2">
              <wizard-prev-btn-left @click="goPrev"></wizard-prev-btn-left>
            </div>
            <div>
              <wizard-submit-btn-left :label="$t('Save as Confirmed')" @click="handleSubmit(submit)">
              </wizard-submit-btn-left>
              <wizard-next-btn-left @click="handleNext"></wizard-next-btn-left>
            </div>
          </wizard-actions-left>
        </validation-observer>
      </template>
    </wizard-left>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import InvitationService from "@/core/services/invitation/invitation.service";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import { mapState } from "vuex";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import GroupService from "@/core/services/group/group.service";
import Swal from "sweetalert2";
import moment from "moment";
import {backendErrorSwal} from "@/core/helpers/swal";

export default {
  components: {
    datePicker,
  },

  data() {
    return {
      staffErrors: null,
      isSubmitting: false,
      inviteType: "apstaff",
      loading: true,
      userSetup: {
        results: [],
        selected: false,
      },
      physicalPersonInfo: {
        name: "",
        surname: "",
        date_birth: "",
        groups: [],
        citizenship_set: [],
        login_email: "",
        telcontact_set: []
      },
      GENDERS: [],
      GROUPS: [],
      physicalPersonErros: {},
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    requestBody() {
      const physicalPersonForm = { ...this.physicalPersonInfo };
      return physicalPersonForm;
    },
    computedMsg() {
      let msg = this.$t("Staff user creation failed");
      for (const el in this.observerErrors) {
        if (this.observerErrors[el].length) {
          msg = this.observerErrors[el][0];
          break;
        }
      }
      return msg;
    },
    citizenshipsComp: {
      get() {
        return this.physicalPersonInfo.citizenship_set.map(el => this.$t(el.country));
      },
      set(val) {
        this.physicalPersonInfo.citizenship_set = val.map(el => ({ country: el }));
      },
    },
    nationsSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    datePickerOverrides() {
      return { maxDate: moment() }
    }
  },

  watch: {
    errors: {
      deep: true,
      handler(errors) {
        const userSetupObs = this.$refs[this.OBSERVERS[1]];
        const usErrors = this.usersSetupErrors(errors);
        userSetupObs.setErrors(usErrors);
        const physPersonObs = this.$refs[this.OBSERVERS[2]];
        const ppErrors = this.physicalPersonErrors(errors);
        physPersonObs.setErrors(ppErrors);
      },
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Users") },
      { title: this.$t("Atlasposting Staff"), route: { name: "administration-staff" } },
      { title: this.$t("Create") },
    ]);
    try {
      const [genders, groups] = await Promise.all([
        PhysicalPersonDetailService.getGenders(),
        GroupService.getAllNotPaginated(),
      ]);
      this.GENDERS = genders.map(el => ({ value: el.value, text: el.display_name }));
      this.GROUPS = groups.results.map(el => ({ value: el.id, text: el.name }));
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    async submitEuropostingStaff(body) {
      this.isSubmitting = true;
      try {
        await InvitationService.createInvitation(body);
        Swal.fire("Success", "Mail sent correctly", "success");
        this.$router.push({ name: "administration-staff-manage" });
      } catch (err) {
        if (err?.response?.status === 400) {
          this.staffErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail)
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    usersSetupErrors(apiErrors) {
      const errors = {
        email: [],
      };

      if (!apiErrors) return errors;
      const { email } = apiErrors;
      errors.email = email ?? [];
      return errors;
    },

    physicalPersonErrors(apiErrors) {
      const errors = {
        name: [],
        surname: [],
        avatar_tus: [],
        gender: [],
        place_birth: [],
        date_birth: [],
        citienships: [],
        groups: [],
      };
      if (!apiErrors) return errors;

      const { physicalperson, groups } = apiErrors;

      if (groups) {
        errors.groups = groups ?? [];
      }

      if (physicalperson) {
        errors.name = physicalperson?.name ?? [];
        errors.surname = physicalperson?.surname ?? [];
        errors.avatar_tus = physicalperson?.avatar_tus ?? [];
        errors.gender = physicalperson?.gender ?? [];
        errors.place_birth = physicalperson?.place_birth ?? [];
        errors.date_birth = physicalperson?.date_birth ?? [];
        errors.citizenships_set = physicalperson?.citizenships_set ?? [];
      }

      return errors;
    },

    async submit() {
      const body = {
        email: this.physicalPersonInfo.login_email,
        inviteType: this.inviteType,
        /**
         * @type {PhysicalPerson}
         */
        physicalPerson: {
          name: this.physicalPersonInfo.name,
          surname: this.physicalPersonInfo.surname,
          telcontact_set: this.physicalPersonInfo.telcontact_set ? this.physicalPersonInfo.telcontact_set : [],
        },
        groups_data: this.physicalPersonInfo.groups,
        userID: this.userSetup.id,
      };

      this.submitEuropostingStaff(body);
    },
    async handleNext() {
      const validator = this.$refs[`validator-${this.$refs.userCreateWizardForm.getStep()}`];

      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          this.$nextTick(() => {
            Swal.fire(this.$t("Validation Error"), `${this.computedMsg}`, "error");
          });
          return;
        }
      }
      this.$refs.mainValidationObserver.handleSubmit(this.$refs.userCreateWizardForm.goNext());
    },

    async customNext(currentStep) {
      const validatorRef = this.OBSERVERS[currentStep];
      const validator = this.$refs[validatorRef];
      if (this.userSetup.results.length && (!this.physicalPersonInfo.name || !this.physicalPersonInfo.surname)) return;

      if (validator) {
        const isValid = await validator.validate();
        if (!isValid) return;
      }

      const wizard = this.$refs.userCreateWizardForm;
      wizard.goTo(currentStep + 1);
    },

    customPrev() {
      const wizard = this.$refs.userCreateWizardForm;

      wizard.goPrev();
    },
  },
};
</script>

<style scoped>
.wizard-step-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>

